import React, { Component } from "react";

export default class Ending extends Component {
  render() {
    return (
      <div className="end">
        If you have questions or want to contact me, feel free to connect with
        me on any of the profiles listed on my site.
        <br></br>
      </div>
    );
  }
}
